import {
	Button,
	Image,
	Label,
	Text,
	View,
	useAuthenticator,
	PasswordField,
	Input,
	Divider,
	Link,
} from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify/utils";
import logo from "../assets/Logos/cosmogas-logo-md.png";
import React, { useContext, useEffect, useState } from "react";
import { strings } from "../strings";
import { signOut } from "aws-amplify/auth";
import { Context } from "../contexts/context";
import BasicLayout from "../components/basicLayout";

I18n.putVocabularies(strings);

const Profile = () => {
	const { user } = useContext(Context);
	const [isRedirecting, setIsRedirecting] = useState(false);

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);

		if (queryParams.get("redirect_uri")) {
			setIsRedirecting(true);
		}
	}, [window.location.href]);

	return (
		<BasicLayout>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "stretch",
				}}
			>
				<Text
					headingLevel={1}
					marginBottom={"20px"}
					textAlign={"center"}
				>
					{`${I18n.get("Profile hello")} `}
					<b>{user?.email}</b>
					{"!"}
					<br></br>
				</Text>
				<Text
					headingLevel={2}
					marginBottom={"20px"}
					textAlign={"center"}
				>
					{isRedirecting ? I18n.get("Profile redirecting") : ""}
				</Text>
				<Button
					style={{
						width: "fit-content",
						margin: "0 auto",
					}}
					onClick={async () => {
						await signOut();
						window.location.reload();
					}}
				>
					Logout
				</Button>
			</div>
		</BasicLayout>
	);
};

export default Profile;
