import { Text, View, Link, Image } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify/utils";
import React, { useEffect, useState } from "react";
import BasicLayout from "../components/basicLayout";
import { fetchAuthSession } from "aws-amplify/auth";

const AccountActivationSuccess = () => {
	const [links, setLinks] = useState([]);

	useEffect(() => {
		async function fetchSession() {
			const session = await fetchAuthSession({
				forceRefresh: true,
			});

			if (session?.tokens?.idToken) {
				const idToken = session?.tokens?.idToken.toString();
				const tokenPayload = JSON.parse(atob(idToken.split(".")[1]));

				setLinks(tokenPayload["cognito:groups"] ?? []);
			}
		}

		fetchSession();
	}, [window.location.href]);

	const availablePoints = {
		accesso_documentale: (
			<Link
				href={I18n.get("Account activation success portale link")}
				style={{
					display: "flex",
					padding: "8px",
					gap: "8px",
					fontWeight: "600",
					color: "#4A5C7F",
					border: "1px solid #BFD5FE",
					backgroundColor: "#DBE7FE",
					borderRadius: "8px",
				}}
			>
				<Image
					src={"favicon.ico"}
					alt="favicon"
					width={24}
					height={24}
				></Image>
				{I18n.get("Account activation success portale label")}
			</Link>
		),
		accesso_www: (
			<Link
				href={I18n.get("Account activation success website link")}
				style={{
					display: "flex",
					padding: "8px",
					gap: "8px",
					fontWeight: "600",
					color: "#4A5C7F",
					border: "1px solid #BFD5FE",
					backgroundColor: "#DBE7FE",
					borderRadius: "8px",
				}}
			>
				<Image
					src={"favicon.ico"}
					alt="favicon"
					width={24}
					height={24}
				></Image>
				{I18n.get("Account activation success website label")}
			</Link>
		),

		accesso_cosmoclub: (
			<Link
				href={I18n.get("Account activation success cosmoclub link")}
				style={{
					display: "flex",
					padding: "8px",
					gap: "8px",
					fontWeight: "600",
					color: "#4A5C7F",
					border: "1px solid #BFD5FE",
					backgroundColor: "#DBE7FE",
					borderRadius: "8px",
				}}
			>
				<Image
					src={"favicon.ico"}
					alt="favicon"
					width={24}
					height={24}
				></Image>
				{I18n.get("Account activation success cosmoclub label")}
			</Link>
		),
	};

	return (
		<BasicLayout>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "stretch",
				}}
			>
				<div
					style={{
						width: "56px",
						height: "56px",
						borderRadius: "12px",
						boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						border: "1px solid #D2D8E5",
						margin: "auto auto 24px auto",
						backgroundColor: "white",
					}}
				>
					<svg
						width="28"
						height="28"
						viewBox="0 0 28 28"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M23.3337 7L10.5003 19.8333L4.66699 14"
							stroke="#3D4A67"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
				<View textAlign="center">
					<Text
						as="h2"
						fontSize="30px"
						fontWeight="600"
						lineHeight="30px"
						marginBottom="12px"
						maxWidth="350px"
						marginInline="auto"
					>
						{I18n.get("Account activation success title")}
					</Text>
					<Text
						as="p"
						fontSize="16px"
						fontWeight="500"
						lineHeight="24px"
						marginBottom="32px"
						color="#4A5C7F"
						maxWidth="350px"
						marginInline="auto"
						dangerouslySetInnerHTML={{
							__html:
								links?.length > 0
									? I18n.get(
											"Account activation success description full"
									  )
									: I18n.get(
											"Account activation success description"
									  ),
						}}
					></Text>

					<ul
						style={{
							listStyleType: "none",
							padding: 0,
							margin: "auto",
							display: "flex",
							flexDirection: "column",
							gap: "8px",
						}}
					>
						{links?.map((link) => {
							return <li key={link}>{availablePoints[link]}</li>;
						})}
					</ul>
				</View>
			</div>
		</BasicLayout>
	);
};

export default AccountActivationSuccess;
